const windowHost = window.location.origin;
let pattern = /^(http:\/\/localhost|http:\/\/192\.168|https:\/\/localhost|https:\/\/192\.168|file:\/\/*)/i;
// 判断环境
export const isMain = pattern.test(windowHost) ? false : true;
// 请求api
// 'https://infinitealliance.biz/api'
// 'http://192.168.1.37:28888'
export let baseUrl: string = isMain ? 'https://infinitealliance.biz/api' : 'https://infinitealliance.biz/api';

export let ContractUrl: string = isMain ? 'https://bscscan.com/address/' : 'https://testnet.bscscan.com/address/';

interface contractAddressType {
    [propName: string]: string;
}
export const Main: contractAddressType = {
    TOKEN: '0xfAF18E53F52122085a8743e2bfb324c0577b98B5',
};
const Test = {
    TOKEN: '0xEec2f5a9c17C70081Fed402B04b73B55e291e014',
};

export const contractAddress: contractAddressType = isMain ? Main : Test;
// Test
