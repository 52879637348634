import React, { useCallback, useEffect } from 'react';
import protocol from './pc.module.css';
import logo from '../../assets/image/logo.png';
import { notification } from 'antd';
import { ed25519 } from '@noble/curves/ed25519';
import bs58 from 'bs58';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { AddrHandle } from '../../utils/tool';
import { useTranslation } from 'react-i18next';

const ComponentName = (props?: {}) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const navigate = useNavigate();

    const handleIdo = () => {
        setTabIndex(1);
        navigate('/ido');
    };

    const { setVisible } = useWalletModal();
    const { publicKey, signMessage, disconnect } = useWallet();
    const handleConnect = () => {
        if (!publicKey) {
            setVisible(true);
        } else {
            // disconnect();
        }
    };

    const location = useLocation();
    const handleSetTabIndex = () => {
        switch (location.pathname) {
            case '/':
                setTabIndex(0);
                break;
            case '/ido':
                handleIdo();
                break;
            case '/my':
                setTabIndex(6);
                break;
            case '/mint':
                setTabIndex(7);
                break;
            case '/exchange':
                setTabIndex(2);
                break;
            case '/staking':
                setTabIndex(3);
                break;
            case '/market':
                setTabIndex(4);
                break;
            case '/depin':
                setTabIndex(5);
                break;
            default:
                setTabIndex(-1);
        }
    };

    useEffect(() => {
        handleSetTabIndex();
    }, []);

    const { t, i18n } = useTranslation();

    return (
        <div className={protocol.main}>
            <div className={protocol.main_box}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={logo}
                        onClick={() => {
                            setTabIndex(0);
                            navigate('/');
                        }}
                        style={{ width: '220px', height: '36px' }}
                        alt="logo"
                        className="pointer"
                    />
                    <div style={{ flex: 1 }}></div>
                    <div className={protocol.main_box_right}>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                setTabIndex(0);
                                navigate('/');
                            }}
                        >
                            <div className={`${tabIndex === 0 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('1')}</div>
                            </div>
                        </div>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                setTabIndex(7);
                                navigate('/mint');
                            }}
                        >
                            <div className={`${tabIndex === 7 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('2')}</div>
                            </div>
                        </div>
                        <div className={`${protocol.main_box_right_item}`} onClick={handleIdo}>
                            <div className={`${tabIndex === 1 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('3')}</div>
                            </div>
                        </div>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                setTabIndex(2);
                                navigate('/exchange');
                            }}
                        >
                            <div className={`${tabIndex === 2 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('4')}</div>
                            </div>
                        </div>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                setTabIndex(3);
                                navigate('/staking');
                            }}
                        >
                            <div className={`${tabIndex === 3 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('5')}</div>
                            </div>
                        </div>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                setTabIndex(4);
                                navigate('/market');
                            }}
                        >
                            <div className={`${tabIndex === 4 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('6')}</div>
                            </div>
                        </div>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                // setTabIndex(5);
                                // navigate('/depin');
                                notification.open({
                                  key: 'Commit Soon',
                                  message: 'Commit Soon',
                                  duration: 1,
                              })
                            }}
                        >
                            <div className={`${tabIndex === 5 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('7')}</div>
                            </div>
                        </div>
                        <div
                            className={`${protocol.main_box_right_item}`}
                            onClick={() => {
                                setTabIndex(6);
                                navigate('/my');
                            }}
                        >
                            <div className={`${tabIndex === 6 ? protocol.main_box_right_item_on : ''}`}>
                                <div className={`${protocol.main_box_right_item_on_bg}`}>{t('8')}</div>
                            </div>
                        </div>
                        <svg
                            className="pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="31"
                            viewBox="0 0 30 31"
                            fill="none"
                        >
                            <path
                                d="M15 2.53516C7.82595 2.53516 2 8.36111 2 15.5352C2 22.7092 7.82595 28.5352 15 28.5352C22.174 28.5352 28 22.7092 28 15.5352C28 8.36111 22.174 2.53516 15 2.53516ZM26.0259 14.5725H22.6557C22.5114 11.0092 21.5481 7.87946 20.0557 5.71301C21.721 6.56165 23.1428 7.82023 24.1873 9.37018C25.2318 10.9201 25.8645 12.7105 26.0259 14.5725ZM14.0373 4.60541V14.5732H9.27025C9.46265 9.61366 11.4854 5.47316 14.0373 4.60606V4.60541ZM14.0373 16.4978V26.4649C11.4854 25.5978 9.46265 21.4573 9.27025 16.4978H14.0373ZM15.9626 26.4649V16.4972H20.7297C20.5373 21.4567 18.5145 25.5972 15.9626 26.4643V26.4649ZM15.9626 14.5725V4.60541C18.5145 5.47251 20.5373 9.61301 20.7297 14.5725H15.962H15.9626ZM9.8962 5.71301C8.4038 7.87946 7.4405 11.0092 7.2962 14.5725H3.92595C4.3114 10.7206 6.62215 7.39781 9.8962 5.71301ZM3.97405 16.4972H7.3443C7.4886 20.0605 8.4519 23.1902 9.9443 25.3567C8.27904 24.508 6.8572 23.2494 5.81272 21.6995C4.76823 20.1495 4.13548 18.3592 3.97405 16.4972ZM20.1038 25.3567C21.5962 23.1902 22.5595 20.0605 22.7038 16.4972H26.0741C25.6886 20.3491 23.3779 23.6719 20.1038 25.3567Z"
                                fill="white"
                            />
                        </svg>
                        <div className={`${protocol.ml_10}`}></div>
                        <div className={`${protocol.main_box_wallet}`} onClick={() => handleConnect()}>
                            {publicKey ? AddrHandle(publicKey?.toBase58()) : t('Please link wallet')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComponentName;
